jQuery.extend({
    mFilter: function(holder) {
        // PUBLIC
        var _this = this

        var _private = {
            // PRIVATE OBJECTS
            holder: _this,
            defaultActive: null,
            registeredTiles: null,
            grid: null,

            // PRIVATE FUNCTIONS
            setup: function() {
                _private.holder = $(holder)
                _private.filtersTop = $(".m-filter__categories")
                _private.filtersTopList = _private.filtersTop.find(
                    ".m-filter__categories__list"
                )

                _private.grid = $(".o-grid")
                _private.gridItems = _private.grid.find(".o-grid__item")
            },
            registerTiles: function() {
                var map = {}

                _private.gridItems.each(function(index, element) {
                    var ids = $(element).data("categories")

                    $(ids).each(function(index, id) {
                        if (map[id] === undefined) {
                            map[id] = []
                        }
                        map[id].push($(element))
                    })
                })

                _private.registeredTiles = map
            },

            setupFilterClickListeners: function() {
                _private.filtersTopList.find("li").bind("click", function() {
                    _private.filter($(this).data("id"));
                }) 
            },

            filter: function(categoryId) {
                _private.updateFiltersArray(categoryId);
                _private.updateTiles();

            },

            updateTiles: function() {
                _private.holder
                    .find(".m-filter__categories__list li")
                    .removeClass("is--active")
                $(_private.gridItems).removeClass("is--inactive")

                var activeFilters = _private.getFilters();

                if (activeFilters.length > 0) {
                    _private.holder
                        .find(".m-filter__categories__list [data-id=" + 'all' + "]")
                        .removeClass("is--active")
                    $(_private.gridItems).addClass("is--inactive")

                    $(activeFilters).each(function(index, element) {
                        _private.holder
                            .find(".m-filter__categories__list [data-id=" + element + "]")
                            .addClass("is--active")
                        $(_private.registeredTiles[element]).each(function(
                            index,
                            element
                        ) {
                            $(element).removeClass("is--inactive")
                        })
                    })
                } else {
                    _private.holder
                        .find(".m-filter__categories__list [data-id=" + 'all' + "]")
                        .addClass("is--active")                }
            },
            updateFiltersArray: function(categoryId) {
                if (categoryId === "all") {
                    setFilters([]);
                } else {
                    var filters = _private.getFilters();
                    var index = filters.indexOf(categoryId.toString());

                    if (index > -1) {
                        _private.removeFilter(index);
                    } else {
                        _private.addFilter(categoryId)
                    }
                }
            },
            addFilter: function(categoryId) {
                var filters = _private.getFilters();
                filters.push(categoryId);
                _private.setFilters(filters);
            },
            removeFilter: function(index) {
                var filters = _private.getFilters();
                filters.splice(index, 1);
                _private.setFilters(filters);
            },
            getFilters: function() {
                filtersRaw = Cookies.get('filters');
                return filtersRaw ? filtersRaw.split('-') : [];
            },
            setFilters: function(filterArray) {
                var cookieString = "";
                for (var i = 0; i < filterArray.length; i++) {
                    cookieString += "-" + filterArray[i];
                }
                Cookies.set('filters', cookieString.length > 0 ? cookieString.substring(1) : "", { expires: 1 });
            }
        }

        function initialize() {
            _private.setup()
            _private.registerTiles()
            _private.setupFilterClickListeners()
            _private.updateTiles()
        }
        $(document).ready(function() {
            initialize()
        })
    }
})
$(function() {
    $(document).ready(function() {
        $(".m-filter").each(function(_index, _ell) {
            var mFilter = new $.mFilter($(_ell))
        })
    })
})
