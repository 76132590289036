jQuery.extend({

    mTiles: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder              	   				: _this,
            holderFilters							: null,
            screenWidth								: null,
            paddingBottom 							: 4,

            // PRIVATE FUNCTIONS
            setup:function()
            {
	            _private.holder 					= $(holder);
	            _private.holderFilters				= $(".m-filter__categories");
	            
	            _private.holderFilters.find("li").bind("click", _private.update);
            },
            update:function()
            {
	            var _posTop							= 0;								// Current vertical position
	            var _posLeft						= 0;								// Current horizontal position
	            var _rowHeight						= 0;								// Current row height
	            var _totalHeight					= 0;								// Total row height
	            
	            _private.screenWidth 				= window.innerWidth;				// Window width
	            
	            var _tiles 							= _private.holder.find(".o-grid__item:visible");

	            _tiles.each(function(_index, _tile)
	            {
	            	// Tile dimensions including margin and borders:

	            	var _tile 						= $(_tile);

	            	var _width 						= _tile.outerWidth(true);
	            	var _height 					= _tile.outerHeight(true);


	         		// Dimensions of next tile for row wrapping:

	         		var _nextTile					= _tiles.length > _index + 1 ? $(_tiles.eq(_index + 1)) : null;

	            	var _nextwidth					= _nextTile ? _nextTile.outerWidth(true) : 0;
	            	var _nextHeight					= _nextTile ? _nextTile.outerHeight(true) : 0;


       				// Determine row height:

            		_rowHeight 						= _height > _rowHeight ? _height : _rowHeight;


	            	// Set tile transform to current position:

       				_tile.css('transform', 'translate3d(' + _posLeft + 'px,' + _posTop + 'px, 0px');


       				// Calculate new position:

		            if (_posLeft + _width + _nextwidth > _private.screenWidth) {

		            	// Tile is placed on new row

		            	_posLeft					= 0;
		            	_posTop						= _posTop + _rowHeight;
		            	_totalHeight				= _totalHeight + _rowHeight;
		            	_rowHeight					= 0;

		            } else {

		            	// Tile fits within current row

		            	_posLeft					= _posLeft + _width;

		            }
		            
	            });


	            // Add last row to total height and set to container

	            _totalHeight 						= _totalHeight + _rowHeight + _private.paddingBottom;

	            _private.holder.css('height', _totalHeight+ 'px');

            }     
        };
        function initialize()
        {
	        _private.setup();
	        _private.update();
        }
        $(document).ready(function()
        {
            initialize();
        });
        $( window ).resize(function()
        {
		  	_private.update();
		});
    }
});
$(function() {
    $( document ).ready(function()
    {
	    var mTiles = new $.mTiles($("#container-tiles"));
    });
});
