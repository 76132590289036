jQuery.extend({
    mProjects: function(holder) {
        // PUBLIC
        var _this = this;

        var _private = {
            // PRIVATE OBJECTS
            holder: _this,
            itemWidth: 35,

            // PRIVATE FUNCTIONS
            setup: function() {
                _private.holder = $(holder);
                _private.slider = _private.holder.find(".m-projects__slider");
                _private.sliderContainer = _private.holder.find(".m-projects__slider__container");
                _private.slide = _private.sliderContainer.find(".m-projects__slider__item");

                _private.countSlide = _private.slide.length;

                _private.sliderNav = _private.holder.find(".m-projects__slider__nav");
                _private.sliderNavHolder = _private.holder.find(".m-projects__slider__nav__container");
                _private.navItem = _private.sliderNav.find("li");
                _private.countNavItem = _private.navItem.length;

                _private.slide.first().addClass("is--active");
                _private.navItem.first().addClass("is--active");

                _private.position = 0;

                _private.navButtonWidth = 0;

                _private.navButtonLeft = _private.holder.find(".m-projects__slider__nav__button--left");
                _private.navButtonRight = _private.holder.find(".m-projects__slider__nav__button--right");

                _private.navButtonLeft.bind("click", _private.moveLeft);
                _private.navButtonRight.bind("click", _private.moveRight);
                _private.navButtonLeft.hide();
            },
            setPropperties: function() 
            {
                if (_private.sliderNav.width() + 90 > $(window).width()) {
                    _private.slider.addClass("is--wider");
                    _private.slider.width($(window).width()+ _private.navButtonWidth);
                    _private.sliderNav.width((Math.round(($(window).width() - 90) / _private.itemWidth) - 1) * _private.itemWidth + _private.navButtonWidth);
                    _private.sliderNavHolder.width(_private.countNavItem * _private.itemWidth - 10 + 90 + _private.navButtonWidth);
                } else {
                    _private.slider.removeClass("is--wider");
                    _private.slider.width(_private.countNavItem * _private.itemWidth - 10+ _private.navButtonWidth);
                    _private.sliderNav.width(_private.countNavItem * _private.itemWidth - 10 + _private.navButtonWidth);
                    _private.sliderNavHolder.width(_private.countNavItem * _private.itemWidth - 10 + _private.navButtonWidth);
                }
            },
            showSlide: function() {

                if (_private.countNavItem === 0) {
                    _private.navButtonLeft.hide();
                    _private.navButtonRight.hide();
                }

                if (_private.position === 0) {
                    _private.navButtonLeft.hide();
                    _private.navButtonRight.show();
                } else if (_private.position === _private.countNavItem - 1) {
                    _private.navButtonLeft.show();
                    _private.navButtonRight.hide();
                } else {
                    _private.navButtonLeft.show();
                    _private.navButtonRight.show();
                }

                _private.navItem.removeClass("is--active");
                _private.navItem.eq(_private.position).addClass("is--active");

                _private.slide.removeClass("is--active");
                _private.slide.fadeOut(300);
                _private.slide.eq(_private.position).fadeIn(300);
            },
            setClick: function() {
                _private.navItem.bind("click", function() {
                    _private.position = $(this).index();
                    _private.showSlide();
                });
            },
            moveRight: function() {
                _private.position++;
                _private.showSlide();
            },
            moveLeft: function() {
                _private.position--;
                _private.showSlide();
            }
        };
        function initialize() {
            _private.setup();
            _private.setPropperties();
            _private.setClick();
        }
        $(document).ready(function() {
            initialize();
        });

        // WINDOW RESIZE
        function doResize() {
            _private.setPropperties();
        }

        var resizeTimer;
        $(window).resize(function() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(doResize, 1000);
        });
    }
});
$(function() {
    $(document).ready(function() {
        $(".m-projects__detail").each(function(_index, _ell) {
            var mProjects = new $.mProjects($(_ell));
        });
    });
});
