jQuery.extend({

    oSelect: function(holder){

        // PUBLIC
        var _this               = this;

        var _private = {

            // PRIVATE OBJECTS
            holder              	: _this,
            btnToggle               : null,
            defaultActive           : null,

            // PRIVATE FUNCTIONS
            setup:function()
            {
                _private.holder             = $(holder);
                _private.body               = $('body');
                _private.btnToggle          = $(holder).find(".o-select__toggle");
                _private.selectItem         = _private.holder.find(".o-select__menu").find("a");
                _private.defaultActive      = _private.holder.find(".o-select__menu").find("a.active");
                _private.multiSelect        = _private.body.find('.o-select--multi');
                _private.multiSelectItem    = _private.multiSelect.find(".o-select__menu").find("a");
                _private.filterTagHolder    = _private.body.find('.m-product-filter__tags').find('ul');

                _private.doCollapse         = _private.holder.data('collapse');

                if(_private.holder.hasClass('o-select--multi')) {
                    _private.selectMultiple();
                }
            },
            toggleDropdown:function() {
                // Close menu when click outside
                $(document).mouseup(function (e)
                {
                    if (!_private.btnToggle.is(e.target) && _private.btnToggle.has(e.target).length === 0)
                    {
                        $(holder).removeClass("open");
                    }
                });

                _private.btnToggle.bind('click', function() {

                    if (!$(holder).hasClass("open")) {
                        $(holder).addClass("open");
                    }

                    else if ($(holder).hasClass("open")){
                        $(holder).removeClass("open");
                    }
                });
            },
            selectMultiple:function() {

                _private.multiSelectItem.bind('click', function(event) {
                    event.preventDefault();

                    var ell = $(this);
                    var catergoryId = ell.data('id');
                    var checkbox = ell.find('.o-checkbox__input');

                    var tag = ell.data('filter');
                    var tagLabel = ell.find('.o-checkbox__label').text();
                    
                    if(checkbox.is(':checked')) {
                        ell.removeClass('active');
                        checkbox.prop('checked', null);
                        _private.removeSelectedTag(tag);
                    } else {
                        ell.addClass('active');
                        checkbox.prop('checked', true);
                        _private.addSelectedTag(tag, tagLabel, catergoryId);
                    }
        
                });
                
            },
            addSelectedTag:function(tag, label, catergoryID) {
                _private.filterTagHolder.append('<li class="m-product-filter__tags__item o-label" data-filter="'+tag+'" onclick="cat_ajax_get('+catergoryID+')">'+label+'<i class="icon icon-cross"></i></li>');

                // TRIGGER FUNCTION FOR UNSELECTING FILTER
                _private.uncheckSelected();
            },
            removeSelectedTag:function(tag) {
                _private.filterTagHolder.find('[data-filter="'+tag+'"]').remove();
            },
            uncheckSelected:function() {

                _private.filterTagHolder.find('li').bind('click', function(event) {
                    event.preventDefault();
                    var tag = $(this).data('filter');

                    var selectboxItem   = _private.multiSelect.find('[data-filter="'+tag+'"]'); 
                    var checkbox        = selectboxItem.find('.o-checkbox__input');

                    // RESET SELECTBOX ITEM
                    selectboxItem.removeClass('active');
                    checkbox.prop('checked', null);

                    // REMOVE CLICKED TAG
                    _private.removeSelectedTag(tag);
                });
            },
            clickFilterListeners:function() {

                _private.holder.find(".o-select__menu").find("a").each(function(_index, _ell) {
                    $(this).bind('click', function() {
                        var ell = $(this);
                        _private.clickFilter(ell);

                        if(!_private.doCollapse) {
                            $(holder).addClass("open");
                        }
                    });
                });

            },
            clickFilter:function(ell) {

                var _placeholder = $(ell).closest(_private.holder).find(".o-btn__txt");

                if(!_private.holder.hasClass('o-select--multi')) {
                    if ($(ell).hasClass("active")) {
                        // var _textInitial = _placeholder.attr("data-placeholder");

                        // _placeholder.text(_textInitial);
                        // $(ell).removeClass("active");
                        // _private.defaultActive.addClass("active");
                        // _private.updateDropdown(ell);
                    } else {
                        var _placeholderText = $(ell).text();

                        _placeholder.text(_placeholderText);

                        $(ell).parent().siblings().find("a").removeClass("active");
                        $(ell).addClass("active");

                        _private.updateDropdown(ell);
                    }
                }

            },
            updateDropdown:function(ell)
            {
                if($(ell).hasClass("active"))
                {
                    _private.holder.attr("value", $(ell).attr("data-filter"));
                }else{
                    _private.holder.attr("value", "");
                }
			},
			
        };
        function initialize()
        {
            _private.setup();
            _private.toggleDropdown();
            _private.clickFilterListeners();

        }
        $(document).ready(function()
        {
            initialize();
        });
    }
});
$(function() {
    $( document ).ready(function()
    {
        $(".o-select").each(function(_index, _ell)
        {
            var oSelect = new $.oSelect($(_ell));
        });
    });
});
